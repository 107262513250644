import React from "react";
import { DocumentIcon } from "@heroicons/react/24/outline";

interface Props {
  enableSpacing?: boolean;
  showIcon?: boolean;
}

const NoData: React.FC<Props> = ({ enableSpacing = true, showIcon = true }) => {
  return (
    <div className={enableSpacing ? 'py-8' : ''}>
        {showIcon && <DocumentIcon className="mx-auto h-6 w-6 text-neutral-500" />}
        <div className="mt-2 text-center text-sm text-neutral-500">No data</div>
    </div>
  );
};

export default NoData;
