import MenuLink, { menuItemClasses } from "./MenuLink";

import Link from "next/link";
import { MenuItem } from "./useMenuItems";
import cx from "classnames";
import { useRouter } from "next/router";
import { useState } from "react";

interface Props {
  menuItem: MenuItem;
}

const ExpandableMenuItem: React.FC<Props> = ({ menuItem }) => {
  const router = useRouter();
  const [open, setOpen] = useState(false);

  return (
    <>
      <li
        className={cx(
          menuItemClasses,
          "flex gap-3",
          router.pathname.startsWith(menuItem.link) && "text-brand-600"
        )}
        onClick={() => setOpen(!open)}
      >
        {menuItem.text}{" "}
        <div className={cx("transition-transform", open && "rotate-45")}>+</div>
      </li>

      <ul
        className={cx(
          "overflow-hidden transition-all",
          open ? "max-h-32" : "max-h-0"
        )}
      >
        {menuItem.subItems?.map((mi) => (
          <MenuLink
            linkSuffix={menuItem.link}
            key={mi.link}
            menuItem={mi}
            classNames="ml-6 mt-0 text-[18px] leading-3"
            onClick={() => setOpen(false)}
          />
        ))}
      </ul>
    </>
  );
};

export default ExpandableMenuItem;
