import { AnimatePresence, motion } from "framer-motion";

import { ReactNode } from "react";

interface Props {
  children: ReactNode;
  transitionKey: string;
  duration?: number;
  className?: string;
  opacityOnly?: boolean;
}

const Transition: React.FC<Props> = ({
  children,
  transitionKey,
  duration = 0.2,
  className,
  opacityOnly,
}) => {
  const variants = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: opacityOnly ? 0 : -5 },
  };
  return (
    <AnimatePresence initial={false} mode="wait">
      <motion.div
        className={className}
        key={transitionKey}
        variants={variants}
        initial="hidden"
        animate="visible"
        transition={{ duration }}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
};

export default Transition;
