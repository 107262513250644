import React, { useMemo } from "react";

import Badge from "components/ui/Badge";
import Link from "next/link";
import { MenuItem } from "./useMenuItems";
import cx from "classnames";
import { useRouter } from "next/router";

interface Props {
  menuItem: MenuItem;
  classNames?: string;
  linkSuffix?: string;
  onClick?: () => void;
}

export const menuItemClasses =
  "mt-2 rounded-lg py-2 px-3 text-xl font-light text-neutral-400 transition-colors duration-200 hover:cursor-pointer  hover:text-neutral-200 active:text-brand-300";

const MenuLink: React.FC<Props> = ({
  menuItem,
  classNames,
  linkSuffix = "",
  onClick,
}) => {
  const router = useRouter();

  const isHome = router.asPath === "/" && menuItem.link === "/";

  return (
    <Link
      href={linkSuffix + menuItem.link}
      onClick={onClick}
      className="relative block w-fit"
    >
      {menuItem.badge && <Badge>{menuItem.badge}</Badge>}
      <li
        className={cx(
          menuItemClasses,
          ((router.asPath.startsWith(menuItem.link) && menuItem.link !== "/") ||
            isHome) &&
            "text-brand-600",
          classNames
        )}
      >
        {menuItem.text}
      </li>
    </Link>
  );
};

export default MenuLink;
