import React, { useEffect } from "react";

import PageTransition from "./PageTransition";
import Sidebar from "./Sidebar/Sidebar";
import TopBar from "./TopBar/TopBar";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";
import { userSelector } from "utils/redux/user/userSlice";

interface Props {
  children: React.ReactNode;
}

const Layout: React.FC<Props> = ({ children }) => {
  const router = useRouter();
  const user = useSelector(userSelector);

  useEffect(() => {
    if (!user) router.push("/auth/login");
  }, [router, user]);

  if (!user) return <div className="flex h-full w-full bg-neutral-200" />;

  return (
    <div className="from  flex h-full w-full animate-fadeIn overflow-y-scroll">
      <div className="relative mx-auto box-border  flex w-full max-w-[1680px] bg-neutral-200">
        <Sidebar />
        <div className="mt-[80px] flex w-full flex-col pt-4">
          <PageTransition>{children}</PageTransition>
        </div>
      </div>
    </div>
  );
};

export default Layout;
