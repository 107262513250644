const consts = {
  defaultIssuerId: "a2b88390-1ef5-4c87-87ab-8b0fbe130da7",
  defaultCurrencyId: "9c6df2ac-cec2-4484-a5d9-cc254bbf572a",
  defaultCurrencySymbol: "EURT",
  issuerAddress: "rfwWoXMYjkCWbBgJvCQpq2qBvRqbjCdmKA", // minister of finance
  explorerTransactionsLink:
    "https://custom.xrpl.org/demo.rippled.proxy1.depositorycenter.com:6005/transactions",
  explorerAccountsLink:
    "https://custom.xrpl.org/demo.rippled.proxy1.depositorycenter.com:6005/accounts",
};

export default consts;
