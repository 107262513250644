import api from "@/api";
import { useQuery } from "@tanstack/react-query";
import Loading from "components/ui/Loading";
import React from "react";
import cx from "classnames";
import NoData from "components/ui/NoData";

interface Props {
  tokenId: string;
}

type OrderbookOffer = { price: number; quantity: number };

const Orderbook: React.FC<Props> = ({ tokenId }) => {
  const { data, isLoading } = useQuery<{
    bids: OrderbookOffer[];
    asks: OrderbookOffer[];
  }>(["orderbook", tokenId], async () => {
    const resp = await api.get("orders/orderBook", {
      params: {
        tokenId,
        lastSeconds: 24 * 60 * 60 // 24 hours
      }
    });
    return resp.data;
  }, {
    enabled: !!tokenId
  });

  if (!!tokenId && isLoading) return <Loading />;

  const noData = data ? [...data.bids, ...data.asks].length === 0 : true

  return (
    <div className="flex h-full flex-col">
      <div className="text-l pb-1">Orderbook - Last 24 Hours</div>
      <div className="flex w-full flex-col flex-1 overflow-auto text-center justify-center">
        {
          noData
          ? <NoData />
          : 
            <>
            {data?.bids.map((offer) => (
              <Offer key={offer.price} offer={offer} color="green" />
            ))}
            {data?.asks.map((offer) => (
              <Offer key={offer.price} offer={offer} color="red" />
            ))}
            </>
        }
      </div>
    </div>
  );
};

const Offer = ({
  offer,
  color,
}: {
  offer: OrderbookOffer;
  color: "red" | "green";
}) => {
  return (
    <div className="align gap flex animate-fadeIn justify-center gap-2 rounded px-3 text-sm transition-colors hover:bg-zinc-200">
      <div
        className={cx(
          "flex w-full justify-end",
          color === "red" ? "text-red-600" : "text-green-600"
        )}
      >
        {offer.price}
      </div>
      <div className="flex w-full justify-start">{offer.quantity}</div>
    </div>
  );
};

export default Orderbook;
