import { AnimatePresence, motion } from "framer-motion";

import { ReactNode } from "react";
import Transition from "../ui/effects/Transition";
import { useRouter } from "next/router";

const PageTransition: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { asPath } = useRouter();

  return (
    <Transition transitionKey={asPath} duration={0.1} className="h-full">
      {children}
    </Transition>
  );
};

export default PageTransition;
