import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";

import storage from "redux-persist/lib/storage";
import userSlice from "./user/userSlice";
import balanceSlice from "./user/balanceSlice";

const rootReducer = combineReducers({
  user: userSlice.reducer,
  balance: balanceSlice.reducer,
});

const persistConfig = {
  key: "root",
  storage,
};

const store = configureStore({
  reducer: persistReducer(persistConfig, rootReducer),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export type RootState = ReturnType<typeof rootReducer>;

export const staticDispatch = store.dispatch;
export const getStaticReduxStore = () => store.getState() as RootState;

export const persistor = persistStore(store);

export default store;
