import {
  ArrowLeftOnRectangleIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/outline";
import React, { useState } from "react";
import { removeUser, userSelector } from "utils/redux/user/userSlice";
import { useDispatch, useSelector } from "react-redux";

import Link from "../../ui/Link";
import Popover from "../../ui/Popover";
import cx from "classnames";
import { useRouter } from "next/router";
import { useQuery } from "@tanstack/react-query";
import api from "@/api";
import { CurrencyDto } from "models/Currency";
import Paged from "models/Paged";
import consts from "utils/consts";
import { setBalance } from "utils/redux/user/balanceSlice";

interface Props {}

const UserDropdown: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const [popoverOpen, setPopoverOpen] = useState(false);
  const user = useSelector(userSelector);

  const { data: balance } = useQuery(
    ["balance", user?.id, consts.defaultCurrencyId],
    async () => {
      const resp = await api.get<Paged<CurrencyDto & { balance: number }>>(
        `/investors/${user?.id}/currencies`
      );
      const currency = resp.data.objectList.find(
        (c) => c.id === consts.defaultCurrencyId
      );

      const balance = currency?.balance || 0

      await dispatch(setBalance(balance));

      return balance;
    }
  );

  return (
    <div className="flex  h-full items-center  gap-3 rounded-md p-3 pr-4 ">
      <div className="flex items-center gap-3">
        <div className="flex flex-col items-end leading-3">
          <div className="text-md whitespace-nowrap font-medium text-zinc-400">
            {user?.name}
          </div>
        </div>
        <div className="flex flex-col items-start rounded-lg bg-zinc-700 py-[5px] px-3">
          <div className="text-xs text-zinc-500">Your balance</div>
          <div className="text-sm font-light text-zinc-200">{balance} {consts.defaultCurrencySymbol}</div>
        </div>
      </div>
      <Popover
        onChange={(state) => setPopoverOpen(state)}
        buttonEl={
          <div
            className={cx(
              "flex h-8 w-8 transform cursor-pointer items-center justify-center rounded-full bg-brand-700  transition-all duration-300 hover:bg-brand-600 active:bg-brand-800",
              popoverOpen && "rotate-180"
            )}
          >
            <ChevronDownIcon width={20} />
          </div>
        }
      >
        <Link
          className="flex items-center gap-2 font-semibold"
          onClick={() => {
            dispatch(removeUser());
          }}
        >
          <ArrowLeftOnRectangleIcon className="h-5" /> Log out
        </Link>
      </Popover>
    </div>
  );
};

export default UserDropdown;
