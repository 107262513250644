import { ProgressBar } from "react-loader-spinner";
import React from "react";
import cx from "classnames";

type Props = { height?: number; width?: number } & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

const Loading: React.FC<Props> = ({
  height = 80,
  width = 80,
  className,
  ...props
}) => {
  return (
    <div
      {...props}
      className={cx(
        "flex animate-fadeIn items-center justify-center",
        className
      )}
    >
      <ProgressBar
        height={height}
        width={width}
        borderColor="#404040"
        barColor="#F27D62"
      />
    </div>
  );
};

export const LoadingOverlay: React.FC<Props> = ({
  height = 200,
  className,
  ...props
}) => {
  return (
    <div
      {...props}
      className={cx(
        "absolute inset-0 z-10 animate-fadeIn bg-neutral-200/30",
        className
      )}
    >
      <Loading height={height} />
    </div>
  );
};

export default Loading;
