import ExpandableMenuLink from "./ExpandableMenuLink";
import Image from "next/image";
import MenuLink from "./MenuLink";
import React from "react";
import logo from "../../../../public/Logo.svg";
import useMenuItems from "./useMenuItems";
import UserDropdown from "../TopBar/UserDropdown";

interface Props {}

const Sidebar: React.FC<Props> = () => {
  const menuItems = useMenuItems();

  return (
    <nav className="fixed left-0 z-50 flex h-[80px] w-full  justify-center bg-neutral-900 p-3">
      <div className="flex w-full max-w-[1640px] justify-between">
        <div className="flex">
          <h1 className="p-3 pb-8 text-2xl font-medium text-neutral-200">
            <Image src={logo.src} alt="Axiology" width={150} height={100} />
          </h1>
          <ul className="ml-16 flex">
            {menuItems.map((mi) =>
              mi.subItems ? (
                <ExpandableMenuLink key={mi.link} menuItem={mi} />
              ) : (
                <MenuLink key={mi.link} menuItem={mi} />
              )
            )}
          </ul>
        </div>
        <UserDropdown />
      </div>
    </nav>
  );
};

export default Sidebar;
