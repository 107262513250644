import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface BalanceState {
  balance: number;
}

const initialState: BalanceState = {
  balance: 0
};

const balanceSlice = createSlice({
  name: "balance",
  initialState,
  reducers: {
    setBalance: (state: BalanceState, action: PayloadAction<number>) => {
      state.balance = action.payload;
    },
  },
});

export const { setBalance } = balanceSlice.actions;
export const balanceSelector = (state: RootState) => state.balance.balance;

export default balanceSlice;
