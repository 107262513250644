import React from "react";
import cx from "classnames";

interface Props {
  children: React.ReactNode;
}

const Badge: React.FC<Props> = ({ children }) => {
  return (
    <div className="absolute -top-0 -right-2 flex min-h-[16px] min-w-[16px] items-center justify-center rounded-full bg-brand-700 px-1 text-xs text-neutral-800">
      {children}
    </div>
  );
};

export type StatusBadgeColor = "green" | "red" | "blue" | "yellow";

interface StatusBadgeProps {
  color?: StatusBadgeColor;
  text: string;
}

export const StatusBadge: React.FC<StatusBadgeProps> = ({ color, text }) => {
  let colors = {
    bg: "bg-green-50",
    ring: "ring-green-600/20",
    text: "text-green-700"
  };

  switch(color) {
    case "red":
      colors = {
        bg: "bg-red-50",
        ring: "ring-red-600/20",
        text: "text-red-700"
      };
      break;
    case "blue":
      colors = {
        bg: "bg-blue-50",
        ring: "ring-blue-600/20",
        text: "text-blue-500"
      };
      break;
    case "yellow":
        colors = {
          bg: "bg-yellow-50",
          ring: "ring-yellow-600/20",
          text: "text-yellow-700"
        };
      break;
  }

  return (
    <span className={cx("inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset",
      colors.bg,
      colors.ring,
      colors.text
    )}>
      {text?.toUpperCase()}
    </span>
  );
};

export default Badge;
