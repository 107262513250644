import { Popover as HUIPop, Transition } from "@headlessui/react";
import React, { Fragment, ReactNode, useRef, useState } from "react";

import useClickOutside from "use-click-outside";

interface Props {
  buttonEl: ReactNode;
  children: ReactNode;
  onChange?: (state: boolean) => void;
}

const Popover: React.FC<Props> = ({ children, buttonEl, onChange }) => {
  const [open, setOpen] = useState(false);
  const popRef = useRef<HTMLDivElement | null>(null);
  const toggleMenu = (state?: boolean) => {
    const newState = state === undefined ? !open : state;
    onChange && onChange(newState);
    setOpen(newState);
  };
  useClickOutside(popRef, () => toggleMenu(false));

  return (
    <>
      <HUIPop className="relative transition-opacity" ref={popRef}>
        <div onClick={() => toggleMenu()}>{buttonEl}</div>
        <Transition
          as={Fragment}
          show={open}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <HUIPop.Panel className="absolute z-10 min-w-[140px] rounded-md bg-neutral-50 p-2 shadow-lg right-0">
            {children}
          </HUIPop.Panel>
        </Transition>
      </HUIPop>
    </>
  );
};

export default Popover;
