import React, { ReactNode, useMemo } from "react";

import NextLink from "next/link";
import cx from "classnames";
import consts from "utils/consts";
import { LinkIcon } from "./icons/Link";

type Props = {
  href?: string;
  children: ReactNode;
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
};

const Link: React.FC<Props> = ({
  href,
  children,
  className,
  onClick,
  disabled,
  ...props
}) => {
  const classNames = useMemo(
    () =>
      cx(
        "hover:text-brand-600 active:text-brand-900 transition-colors",
        disabled && "text-neutral-400 cursor-default pointer-events-none"
      ),
    [disabled]
  );

  return href ? (
    <NextLink
      href={href}
      onClick={onClick}
      className={cx(classNames, className)}
      {...props}
    >
      {children}
    </NextLink>
  ) : (
    <div
      className={cx("cursor-pointer ", classNames, className)}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

type ExplorerTransactionLinkProps = { hash: string; className?: string; };

export const ExplorerTransactionLink : React.FC<ExplorerTransactionLinkProps> = ({ hash, className }) => {
  return (
    <a target="_blank" rel="noreferrer" className={cx("flex items-center", className)} href={`${consts.explorerTransactionsLink}/${hash}`}>
      <LinkIcon />
    </a>
  );
};

type ExplorerAccountLinkProps = { account: string; className?: string; };

export const ExplorerAccountLink : React.FC<ExplorerAccountLinkProps> = ({ account, className }) => {
  return (
    <a target="_blank" rel="noreferrer" className={cx("flex items-center", className)} href={`${consts.explorerAccountsLink}/${account}`}>
      <LinkIcon />
    </a>
  );
};


export default Link;
