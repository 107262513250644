import { useSelector } from "react-redux";
import { userSelector } from "utils/redux/user/userSlice";

export type MenuItem = {
  text: string;
  link: string;
  badge?: string;
  subItems?: MenuItem[];
};

const useMenuItems = () => {
  const user = useSelector(userSelector);

  switch (user?.type) {
    case "user":
      return userMenuItems;
    case "issuer":
      return issuerMenuItems;
    default:
      return [];
  }
};

const userMenuItems: MenuItem[] = [
  {
    text: "Portfolio",
    link: "/",
  },
  {
    text: "Markets",
    link: "/markets",
  },
];

const issuerMenuItems: MenuItem[] = [
  {
    text: "Issuance Manager",
    link: "/issuance",
  },
];

export default useMenuItems;
