import "../../styles/globals.css";

import queryClient, { queryPersister } from "../utils/queryClient";
import store, { persistor } from "utils/redux/store";

import type { AppProps } from "next/app";
import Head from "next/head";
import { Hydrate } from "@tanstack/react-query";
import Layout from "components/Layout/Layout";
import NextNProgress from "nextjs-progressbar";
import { PersistGate } from "redux-persist/integration/react";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { Provider } from "react-redux";
import React from "react";
import { Toaster } from "react-hot-toast";
import favIcon from "../../public/favicon.svg";
import { useRouter } from "next/router";

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();

  return (
    <>
      <Head>
        <link rel="icon" href={favIcon.src} />
      </Head>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <PersistQueryClientProvider
            client={queryClient}
            persistOptions={{ persister: queryPersister }}
          >
            <Hydrate state={pageProps.dehydratedState}>
              {router.pathname.startsWith("/auth") ? (
                <Component {...pageProps} />
              ) : (
                <Layout>
                  <Component {...pageProps} />
                </Layout>
              )}

              <Toaster position="bottom-right" />
              <NextNProgress
                color={"#F27D62"}
                showOnShallow={false}
                options={{ showSpinner: false }}
              />
            </Hydrate>
          </PersistQueryClientProvider>
        </PersistGate>
      </Provider>
    </>
  );
}

export default MyApp;
