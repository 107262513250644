import { getStaticReduxStore, staticDispatch } from "./redux/store";

import axios from "axios";
import { removeUser } from "./redux/user/userSlice";
import {
  bondsData,
  chartData,
  couponData,
  couponsData,
  myOrdersData,
  orderbookData,
  ordersData,
  tradesData,
} from "./mocks";

const api = axios.create({
  baseURL:
    process.env.NEXT_PUBLIC_API_URL === "#{NEXT_PUBLIC_API_URL}#"
      ? "https://demo.api.tss.depositorycenter.com/api/v1/"
      : process.env.NEXT_PUBLIC_API_URL,
});

api.interceptors.request.use(
  (config) => {
    const state = getStaticReduxStore();

    const token = state.user.token;
    const expires = state.user.expiresAt;

    if (expires && expires * 1000 < Date.now()) {
      staticDispatch(removeUser());
      window.location.href = "/auth/login";
    }

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 404) {
      const url = error.request.responseURL;

      if (url.includes("price-history"))
        return Promise.resolve({ data: chartData });

      if (url.includes("orderbook"))
        return Promise.resolve({ data: orderbookData });

      if (url.includes("my-orders"))
        return Promise.resolve({ data: myOrdersData });

      if (url.includes("bonds-mocked"))
        return Promise.resolve({ data: bondsData });

      if (url.includes("coupons-mocked"))
        return Promise.resolve({ data: couponsData });

      if (url.includes("coupon-mocked"))
        return Promise.resolve({ data: couponData });

      if (url.includes("comockedupon-"))
        return Promise.resolve({ data: couponData });

      if (url.includes("open-orders"))
        return Promise.resolve({ data: ordersData });

      if (url.includes("trades")) return Promise.resolve({ data: tradesData });
    }

    if (error.response.status === 401) {
      staticDispatch(removeUser());
      window.location.href = "/auth/login";
    }

    return Promise.reject(error);
  }
);

export default api;
