import { QueryClient } from "@tanstack/react-query";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const createNoopStorage = () => {
  return {
    getItem(_key: string) {
      return "";
    },
    setItem(_key: string, value: string) {
      return Promise.resolve(value);
    },
    removeItem(_key: string) {
      return Promise.resolve();
    },
    length: 0,
    key(_index: number) {
      return "";
    },
    clear() {
      return Promise.resolve();
    },
  } satisfies Storage;
};

export const queryPersister = createSyncStoragePersister({
  storage:
    typeof window !== "undefined" ? window.sessionStorage : createNoopStorage(),
});

export default queryClient;
